<div class="row row-form" >
	<label class="control-label col-sm-12" >
		<div class="col-sm-5" >
			{{ dmsTraduction }}
		</div>

		<div class="col-sm-7" >
			<div>
				<ng-content></ng-content>
			</div>
		</div>
	</label> 
</div>