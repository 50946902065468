import { Component, OnInit } from "@angular/core";

import { BaseComponent } from "DMS/Interfaces/base.component";

@Component({
	selector: 'dms-control',
	templateUrl: './control.component.html'
})
export class ControlComponent extends BaseComponent implements OnInit {

}