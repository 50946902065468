import { Component, OnInit } from "@angular/core";

import { FormComponent } from "DMS/Interfaces/form.component";

@Component({
	selector: 'dms-checkbox',
	templateUrl: './checkbox.component.html'
})
export class CheckboxComponent extends FormComponent implements OnInit {

}