import { Injectable } from "@angular/core";
import { Subject, Observable, Subscriber } from "rxjs";
import { pluck } from "rxjs/operators";

import Seed from "DMS/Seed/All";

declare let root : string;

@Injectable({
	providedIn: 'platform'
})
export class LexiqueService {
	traductions : Array<String> = [];
	source : Subject<any>;

	constructor() {
		this.source = new Subject<any>();
	}

	requestTranslation(objnom : string) : Observable<any> {
		if(!(objnom in this.traductions))
			this.traductions.push(objnom);

		return this.source.pipe(pluck(objnom));
	}

	async execute(lang : string, nomliste : string) {		
		return Seed.Ajax.Post({
			url: root + "../../EManage/api/lexique/" + lang + "/" + nomliste,
			async: true,
			json: true,
			postJson: true,
			data: this.traductions
		}).then((json) => {
			this.source.next(json);
		});
	}
}