import { Component, OnInit, Input } from "@angular/core";

import { FormComponent } from "DMS/Interfaces/form.component";

@Component({
	selector: 'dms-select',
	templateUrl: './select.component.html',
})
export class SelectComponent extends FormComponent implements OnInit {
	@Input("dms-param") dmsParam : string = ""; 
}