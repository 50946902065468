<div class="panel panel-primary section" >
	<div class="panel-heading" data-toggle="collapse" (click)="toggleCollapse($event)" aria-expanded="false" >
		<h4 class="panel-title" ><i class="fa fa-chevron-down" ></i>{{ dmsTraduction }}</h4>
	</div>

	<div class="panel-collapse collapse in" data-toggle="collapse" >
		<div class="panel-body" >
			<div class="container-fluid" >
				<div class="angular row section-content" >
					<ng-content></ng-content>
				</div>
			</div>
		</div>
	</div>
</div>