import { Component, OnInit, Input } from "@angular/core";

import { FormComponent } from "DMS/Interfaces/form.component";

@Component({
	selector: "dms-tel",
	templateUrl: "./tel.component.html",
})
export class TelComponent extends FormComponent implements OnInit {
	tel : string = "";

	callPhone() {
		location.href = "tel:+" + this.tel;
	}
}