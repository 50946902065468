import $ from "jquery";
import { Component, OnInit } from "@angular/core";

import { BaseComponent } from "DMS/Interfaces/base.component";

@Component({
	selector: "dms-section",
	templateUrl: "./section.component.html",
})
export class SectionComponent extends BaseComponent implements OnInit {
	toggleCollapse($event : Event) {
		let currentTarget = $(event.currentTarget);

		(<any>currentTarget.parent().find(".collapse")).collapse("toggle");
		currentTarget.find("i.fa").toggleClass("fa-chevron-down fa-chevron-right");
	}
}