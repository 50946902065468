import { Component, Input, OnInit } from "@angular/core";

import { FormComponent } from "DMS/Interfaces/form.component";

@Component({
	selector: 'dms-angular-button',
	templateUrl: './button.component.html'
})
export class ButtonComponent extends FormComponent implements OnInit {
	@Input("dms-icon") dmsIcon: string;
}