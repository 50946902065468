import { Component, OnInit } from "@angular/core";

import { FormComponent } from "DMS/Interfaces/form.component";
import { openModalIframe } from "DMS/Utils/modal";

@Component({
	selector: 'dms-signature',
	templateUrl: './signature.component.html'
})
export class SignatureComponent extends FormComponent implements OnInit {
	openSignatureModal() {
		openModalIframe('Signature.php?bbm=' + this.dmsLabel, this.dmsTraduction); // @todo : bbmindex
	}
}