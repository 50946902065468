import $ from "jquery";
import moment from "moment";
import "eonasdan-bootstrap-datetimepicker";

import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from "@angular/core";

import { FormComponent } from "DMS/Interfaces/form.component";

@Component({
	selector: 'dms-dateheure',
	templateUrl: './dateheure.component.html'
})
export class DateheureComponent extends FormComponent implements OnInit, AfterViewInit {
	@ViewChild("picker", { read: ElementRef })
	picker: ElementRef;

	ngAfterViewInit () {
		(<any>$(this.picker.nativeElement)).datetimepicker({
			locale: moment.locale(),
			icons: {
				time: 'fa fa-clock-o',
				date: 'fa fa-calendar-o',
				up: 'fa fa-arrow-up',
				down: 'fa fa-arrow-down',
				previous: 'fa fa-angle-left',
				next: 'fa fa-angle-right',
				today: 'fa fa-crosshairs',
				clear: 'fa fa-trash'
			},
			showTodayButton: true,
			showClear: true,
			format: "L LT",
			useCurrent: false
		});
	}

	focusOnPicker() {
		$(this.picker.nativeElement).focus();
	}

	setValue(data : any) {
		let date = moment(data);

		super.setValue(date);

		$(this.picker.nativeElement).data("DateTimePicker").date(date);
	}
}