import { Component, OnInit } from "@angular/core";

import { FormComponent } from "DMS/Interfaces/form.component";

@Component({
	selector: 'dms-textbox',
	templateUrl: `./textbox.component.html`
})
export class TextboxComponent extends FormComponent implements OnInit {

}