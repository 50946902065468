import { Component, OnInit, Input } from "@angular/core";

import { FormComponent } from "DMS/Interfaces/form.component";

@Component({
	selector: "dms-mail",
	templateUrl: "./mail.component.html",
})
export class MailComponent extends FormComponent implements OnInit {
	address : string = "";

	sendMail() {
		location.href = "mailto:" + this.address;
	}
}