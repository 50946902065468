import { Component, Input, OnInit, NgZone } from "@angular/core";
import { Subscription } from 'rxjs';

import { LexiqueService } from "DMS/Interfaces/lexique.service";

import Seed from "DMS/Seed/All";

declare let usergroup : string;

@Component({
	template: ``
})
export class BaseComponent implements OnInit {
	@Input("dms-label") dmsLabel : string = "";
	@Input() dmsTraduction : string = "";
	@Input("dms-visible") dmsVisible : string = "";
	@Input() dmsIsVisible : boolean = false;

	lexique : LexiqueService;
	lexiqueSubscription: Subscription;
	ngZone : NgZone;

	constructor(lexique : LexiqueService, ngZone : NgZone) {
		this.lexique = lexique;
		this.ngZone = ngZone;
	}
	
	ngOnInit() {
		if(this.dmsLabel != "") {
			this.lexiqueSubscription = this.lexique.requestTranslation(this.dmsLabel).subscribe((traduction) => {
				this.ngZone.run(() => {
					this.dmsTraduction = traduction;
				});
			});
		}

		this.dmsIsVisible = Seed.Form.isSameGroup(this.dmsVisible, usergroup);
	}

	ngOnDestroy() {
		this.lexiqueSubscription.unsubscribe();
	}
}