import { Component, OnInit, Input, NgZone } from "@angular/core";
import { Subscription } from 'rxjs';

import Seed from "DMS/Seed/All";

import { BaseComponent } from "DMS/Interfaces/base.component";
import { DataService } from "DMS/Interfaces/data.service";
import { LexiqueService } from "DMS/Interfaces/lexique.service";

declare let usergroup : string;

@Component({
	template: ``
})
export class FormComponent extends BaseComponent implements OnInit {
	@Input("dms-table") dmsTable : string = ""; 
	@Input("dms-champ") dmsChamp : string = ""; 
	@Input("dms-index") dmsIndex : number = 999;
	@Input("dms-verrou") dmsVerrou : string = "";

	@Input() dmsValue : any;
	@Input() dmsIsEditable : boolean;

	dataService : DataService;
	dataSubscription: Subscription;

	constructor(lexique : LexiqueService, ngZone : NgZone, dataService : DataService) {
		super(lexique, ngZone);
		this.dataService = dataService;
	}

	ngOnInit() {
		super.ngOnInit();

		if(this.dmsTable != "" && this.dmsChamp != "") {
			this.dataSubscription = this.dataService.requestData(this.dmsTable, this.dmsChamp).subscribe((data) => {
				this.ngZone.run(() => {
					this.setValue(data);
				});
			});
		}

		this.dmsIsEditable = !Seed.Form.isSameGroup(this.dmsVisible, usergroup);
	}

	setValue(value : any) {
		this.dmsValue = value;
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.dataSubscription.unsubscribe();
	}
}