import { Injectable } from "@angular/core";
import { Subject, Observable, Subscriber } from "rxjs";
import { pluck, filter, map } from "rxjs/operators";

import Seed from "DMS/Seed/All";

declare let root : string;

type FieldDms = Map<String, Array<String>>;

@Injectable({
	providedIn: 'platform'
})
export class DataService {
	fields : Map<String, Array<String>> = new Map<String, Array<String>>();
	source : Subject<any>;

	constructor() {
		this.source = new Subject<any>();
	}

	requestData(table : string, champ : string) : Observable<any> {
		if(!this.fields.has(table))
			this.fields.set(table, new Array<String>());
		
		if(!(champ in this.fields.get(table)))
			this.fields.get(table).push(champ);

		return this.source.pipe(pluck(table)).pipe(pluck(champ));
	}

	async execute(bbmindex : string, nomliste : string) {		
		return Seed.Ajax.Post({
			url: root + "../../EManage/api/data/" + nomliste + "/" + bbmindex,
			async: true,
			json: true,
			postJson: true,
			data: this.fields
		}).then((json) => {
			this.source.next(json);
		});
	}
}