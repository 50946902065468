import { NgModule, Injector } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { createCustomElement } from "@angular/elements";

import { SectionComponent } from "DMS/Interfaces/section.component";
import { ButtonBarComponent } from "DMS/Interfaces/buttonbar.component";
import { ColumnComponent } from "DMS/Interfaces/column.component";
import { BaseComponent } from "DMS/Interfaces/base.component";
import { FormComponent } from "DMS/Interfaces/form.component";
import { ControlComponent } from "DMS/Interfaces/control.component";

import { ButtonComponent } from "DMS/Interfaces/Form/button.component";
import { TextboxComponent } from "DMS/Interfaces/Form/textbox.component";
import { CheckboxComponent } from "DMS/Interfaces/Form/checkbox.component";
import { SelectComponent } from "DMS/Interfaces/Form/select.component";
import { SimpleSelectComponent } from "DMS/Interfaces/Form/simpleselect.component";
import { DateheureComponent } from "DMS/Interfaces/Form/dateheure.component";
import { TextareaComponent } from "DMS/Interfaces/Form/textarea.component";
import { MailComponent } from "DMS/Interfaces/Form/mail.component";
import { SignatureComponent } from "DMS/Interfaces/Form/signature.component";
import { PrintComponent } from "DMS/Interfaces/Form/print.component";
import { TelComponent } from "DMS/Interfaces/Form/tel.component";

@NgModule({
	declarations: [ BaseComponent, TextboxComponent, CheckboxComponent, SelectComponent, DateheureComponent, DateheureComponent, TextareaComponent, MailComponent, SectionComponent, ColumnComponent, ButtonBarComponent, ButtonComponent, SignatureComponent, FormComponent, PrintComponent, ControlComponent, SimpleSelectComponent, TelComponent ],
	imports: [ CommonModule, BrowserModule, FormsModule ],
	providers: [],
	entryComponents: []
})
export class AppModule {
	constructor(injector: Injector) {
		customElements.define('dms-section', createCustomElement(SectionComponent, {injector: injector}));
		customElements.define('dms-button-bar', createCustomElement(ButtonBarComponent, {injector: injector}));
		customElements.define('dms-column', createCustomElement(ColumnComponent, {injector: injector}));
		customElements.define('dms-button', createCustomElement(ButtonComponent, {injector: injector}));
		customElements.define('dms-textbox', createCustomElement(TextboxComponent, {injector: injector}));
		customElements.define('dms-checkbox', createCustomElement(CheckboxComponent, {injector: injector}));
		customElements.define('dms-select', createCustomElement(SelectComponent, {injector: injector}));
		customElements.define('dms-dateheure', createCustomElement(DateheureComponent, {injector: injector}));
		customElements.define('dms-textarea', createCustomElement(TextareaComponent, {injector: injector}));
		customElements.define('dms-mail', createCustomElement(MailComponent, {injector: injector}));
		customElements.define('dms-signature', createCustomElement(SignatureComponent, {injector: injector}));
		customElements.define('dms-print', createCustomElement(PrintComponent, {injector: injector}));
		customElements.define('dms-tel', createCustomElement(TelComponent, {injector: injector}));
		// customElements.define('dms-control', createCustomElement(ControlComponent, {injector: injector}));
		// customElements.define('dms-simpleselect', createCustomElement(SimpleSelectComponent, {injector: injector}));
	}

	ngDoBootstrap() { }
}
