import { Component, OnInit } from "@angular/core";

import { FormComponent } from "DMS/Interfaces/form.component";

@Component({
	selector: "dms-textarea",
	templateUrl: "./textarea.component.html",
})
export class TextareaComponent extends FormComponent implements OnInit {

}